import { authHeader } from './auth-header';
import axios from 'axios';
export const userService = {
    login,
    logout,
    register,
    getAll,
};

 function login(email, password) {

    return  axios.post(process.env.VUE_APP_SERVER_URL+'/login',{ email, password } )
        .then(({data}) => {
            // login successful if there's a jwt token in the response
            if (data.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(data));
            }
            return data;
        }).catch((error) => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }
            return error;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {

    

    console.log(response.text());
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
