import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import excel from 'vue-excel-export'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Toasted from 'vue-toasted';
import "../src/design/app.scss";
import store from '@/state/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale })

import App from './App.vue'
import i18n from './i18n'

// import { configureFakeBackend } from './helpers/fake-backend';


Vue.use(excel);
Vue.use(VueRouter)
Vue.use(vco)
Vue.use(ElementUI.DatePicker);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)

Vue.use('VueScrollTo')

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(Toasted,{duration:3000});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
