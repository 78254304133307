import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        services: null,
        service: null,
        opition: null,
        products: [],
        order: {
            customer: null
        },
        usvn: null

    },

    getters: {
        getSerives: (state) => _.cloneDeep(state.services),
        getService: (state) => _.cloneDeep(state.service),
        getOpition: (state) => _.cloneDeep(state.opition),
        getCustomer: (state) => _.cloneDeep(state.order.customer),
        getUsvn: (state)=> JSON.parse(state.usvn)
    },


     mutations : {
        setServices(state, services) {
            state.services = services;
        },
        setService(state, service) {
            state.service = service;
        },
        setOpition(state, opition) {
            state.opition = opition;
        },
        setOrderCustomer(state, data) {
            state.order.customer = data;
        },
        setOrderUsvn(state, data) {
            state.usvn = data;
        }
    }
}
