import store from "@/state/store";

export default [
  {
    path: "/dang-nhap",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  // {
  //   path: "/dang-ky",
  //   name: "Register",
  //   alias: "/register",
  //   component: () => import("./views/account/register"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/dang-xuat",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/dashboards/index")
  },

  {
    path: "/trang-quan-tri",
    name: "admin-dashboard",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/dashboards/admin"),
  },
  {
    path: "/trang-quan-ly",
    name: "member-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/member"),
  },

  {
    path: "/danh-sach-thanh-vien",
    name: "account-add",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: true },
    component: () => import("./views/member/list"), 
  },

  {
    path: "/them-thanh-vien",
    name: "account-add",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: true },
    component: () => import("./views/member/add"), 
  },


  {
    path: "/danh-sach-dich-vu",
    name: "account-add",
    meta: { beforeResolve: true, authRequired: true, isAdmin: true },
    component: () => import("./views/service/index"), 
  },

  {
    path: "/sua-mat-khau",
    name: "edit-password",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/member/edit-password.vue"), 
  },

  {
    path: "/sua-thong-tin-tai-khoan",
    name: "edit-user-info",
    meta: { beforeResolve: true, authRequired: true, isAdmin: true },
    component: () => import("./views/member/edit.vue"), 
  },

  // Service start 
  {
    path: "/sua-gui-hang-us-vn",
    name: "service-us-vn",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/service/edit/service-us-vn"), 
  },
  {
    path: "/sua-gui-hang-vn-us",
    name: "service-vn-us",
    meta: { beforeResolve: true, authRequired: true, isAdmin: true },
    component: () => import("./views/service/edit/service-vn-us"), 
  },
  {
    path: "/sua-gui-hang-us-us",
    name: "service-us-us",
    meta: { beforeResolve: true, authRequired: true, isAdmin: true },
    component: () => import("./views/service/edit/service-us-us"), 
  },

  {
    path: "/sua-gui-hang-duong-tau",
    name: "service-seaway",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: true },
    component: () => import("./views/service/edit/service-seaway"), 
  },

  {
    path: "/sua-gui-tien",
    name: "service-money",
    meta: { beforeResolve: true, authRequired: true, isAdmin: true },
    component: () => import("./views/service/edit/service-money"), 
  },

  {
    path: "/sua-mua-ho-luu-kho",
    name: "service-buy-store",
    meta: { beforeResolve: true, authRequired: true, isAdmin: true },
    component: () => import("./views/service/edit/service-buy-store"), 
  },

  {
    path: "/sua-mua-sam",
    name: "service-shopping",
    meta: { beforeResolve: true, authRequired: true, isAdmin: true },
    component: () => import("./views/service/edit/service-shopping"), 
  },
  // service end
  {
    path: "/danh-sach-don-hang",
    name: "order-list",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/order/list"), 
  },

  {
    path: "/tao-hoa-don",
    name: "bill-add",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/bill/add"), 
  },

  {
    path: "/danh-sach-hoa-don",
    name: "billing-list",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/bill/list"), 
  },

  {
    path: "/chi-tiet-don-hang",
    name: "order-detaild",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/order/detaild"), 
  },

  {
    path: "/chi-tiet-hoa-don",
    name: "bill-detaild",
    meta: { authRequired: true, isAdmin: true },
    component: () => import("./views/bill/detaild"), 
  },

  {
    path: "/hoa-don",
    name: "bill-view",
    meta: { authRequired: false, isAdmin: false, login: false },
    component: () => import("./views/bill/view"), 
  },

  {
    path: "/mau-hoa-don",
    name: "example-bill-view",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: true },
    component: () => import("./views/bill/example"), 
  },
 
  {
    path: "/thong-ke-doanh-so",
    name: " thong-ke-doanh-so",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: false },
    component: () => import("./views/finance/salary/user/view"), 
  },
 


  {
    path: "/thong-ke-doanh-so/:id",
    name: " thong-ke-doanh-so",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: false },
    component: () => import("./views/finance/salary/user"), 
  },
 
  {
    path: "/quan-ly-luong",
    name: "quan-ly-luong",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: true },
    component: () => import("./views/finance/salary/index"), 
  },

  {
    path: "/tong-ket-tai-chinh",
    name: "tong-ket-doanh-thu",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: true },
    component: () => import("./views/finance/index"), 
  },

  {
    path: "/quan-ly-khach-hang",
    name: "quan-ly-khach-hang",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: false },
    component: () => import("./views/customer/index"), 
  },

  {
    path: "/tao-don-hang-moi",
    name: "tao-don-hang-moi",
    meta: {  beforeResolve: true,authRequired: true, isAdmin: false },
    component: () => import("./views/order/add"), 
  },

  
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  
  {
    path: "*",
    redirect: "404",
  }
];